import Vue from "vue";
import router from "./router";

import Axios from "axios";
import VueAxios from "vue-axios";

import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VeeValidate from "vee-validate";
import VueMeta from "vue-meta";
import VueCookies from "vue-cookies";
import VueClipboard from "vue-clipboard2";
import VueMultiselect from "vue-multiselect";

import App from "./App.vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faDollarSign,
  faTimes,
  faSpinner,
  faExclamationTriangle,
  faHome,
  faShieldAlt,
  faQuestionCircle,
  faGlobe,
  faRedo,
  faCheck,
  faEnvelope,
  faAngleDoubleRight,
  faChevronDown,
  faStar,
  faSyncAlt,
  faMapMarkerAlt,
  faPhone,
  faHeart,
  faExternalLinkAlt,
  faPlay,
  faPlus,
  faWrench,
  faShoppingCart,
  faCopy,
  faCreditCard,
  faListUl,
  faKey,
  faEye,
  faBolt,
  faShareAlt,
  faAd,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faNewspaper,
  faPlayCircle,
  faFilePdf,
  faClock,
  faImage,
} from "@fortawesome/free-regular-svg-icons";
import { faWikipediaW, faCcPaypal } from "@fortawesome/free-brands-svg-icons";

Vue.config.productionTip = false;
Axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

Vue.use(VueAxios, Axios);
Vue.use(BootstrapVue);
Vue.use(VeeValidate);
Vue.use(VueMeta);
Vue.use(VueCookies);
Vue.use(VueClipboard);

Vue.component("v-multiselect", VueMultiselect);

library.add(
  faSearch,
  faDollarSign,
  faTimes,
  faSpinner,
  faExclamationTriangle,
  faNewspaper,
  faPlayCircle,
  faHome,
  faShieldAlt,
  faQuestionCircle,
  faGlobe,
  faRedo,
  faCheck,
  faEnvelope,
  faAngleDoubleRight,
  faChevronDown,
  faStar,
  faSyncAlt,
  faFilePdf,
  faMapMarkerAlt,
  faPhone,
  faPlay,
  faHeart,
  faExternalLinkAlt,
  faPlus,
  faWrench,
  faWikipediaW,
  faShoppingCart,
  faCopy,
  faCreditCard,
  faCcPaypal,
  faListUl,
  faClock,
  faImage,
  faKey,
  faEye,
  faBolt,
  faShareAlt,
  faAd,
  faInfoCircle
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({ router, el: "#app", render: (h) => h(App) });
