<template>
  <b-container class="my-5 pb-5">
    <b-row>
      <b-col
        cols="12"
        sm="12"
        md="8"
        offset-md="2"
        lg="6"
        offset-lg="3"
        class="px-0 text-center"
      >
        <p class="mb-2">
          <font-awesome-icon :icon="['fab', 'cc-paypal']" size="lg" />
        </p>
        <h2>Paypal</h2>

        <div v-if="error">
          <p class="mt-5">
            Während des Bezahlvorgangs ist ein Fehler aufgetreten. Bitte mit
            Klick auf den Link an Trooia melden:
          </p>
          <p class="mt-3">
            <b-link
              href="mailto:info@trooia.app?subject=Paypal%20Transaktionsfehler%20aufgetreten"
              class="red"
            >
              <font-awesome-icon icon="envelope" class="mr-1" />info@trooia.app
            </b-link>
          </p>
        </div>
        <div v-else>
          <p class="mt-4 mb-4 red">
            <font-awesome-icon icon="chevron-down" />
          </p>
          <p>
            Bitte den Knopf drücken, um mit Paypal zu bezahlen:
          </p>

          <h4 id="paypal-button" class="mb-0">
            <font-awesome-icon
              v-if="isPaypalLoading"
              icon="spinner"
              class="mt-3 fa-spin text-secondary"
            />
          </h4>
          <div v-if="!isPaypalLoading">
            <p class="mt-5 mb-2">
              <font-awesome-icon
                icon="info-circle"
                size="lg"
                class="text-dark"
              />
            </p>
            <p v-if="hasCode" class="mt-0 px-2  ">
              Ihr Trooia-Zugangscode wird nach Abschluss des Zahlungsvorgangs
              mit
              {{ credits }} Suchanfragen aufgeladen
            </p>
            <p v-else class="mt-0 px-2">
              Sie erhalten nach Abschluss des Zahlungsvorgangs einen
              Trooia-Zugangscode für {{ credits }} Suchanfragen
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { loadScript } from "@paypal/paypal-js";

  const PAYPAL_CLIENT_ID =
    process.env.NODE_ENV == "development"
      ? "ATJNqtZttvNF9cNFxhVFqIo0ES5LS79tAWe88fiI46QFlIbt3bcCZvNs76MjzzEXRstvRHWzb7o2o4XD" // Sandbox
      : "AXNxKfkNjae3PvvHjHwJJWUHrrJcnANDwhngQmuxst9LBdrgGhXH5BzGvorPM8JjSCEjHnvDPMkivJ8A"; // Live

  export default {
    data() {
      return {
        hasCode: false,
        couponCode: "",
        isPaypalLoading: true,
        credits: 250,
        currency: "EUR",
        error: false,
      };
    },
    mounted() {
      // Currency
      const country = this.$route.query.c;
      if (country) {
        if (country == "ch") this.currency = "CHF";
        else this.currency = "EUR";
      } else this.currency = "EUR";

      // Credits
      const credits = this.$route.query.credits;
      if (credits >= 250 && credits <= 2500) {
        this.credits = credits;
      } else {
        this.credits = 250;
      }

      const code = this.$route.query.code;
      if (code) {
        if (code.match(/^[a-f0-9]{32}$/gm)) {
          this.hasCode = true;
          this.couponCode = code;

          try {
            localStorage.code = code;
          } catch (err) {} // eslint-disable-line
          this.$emit("couponCode", code);
        }
      } else {
        try {
          if (localStorage.code) {
            this.hasCode = true;
            this.couponCode = localStorage.code;

            this.$emit("couponCode", localStorage.code);
          }
        } catch (err) {} // eslint-disable-line
      }

      let amount;
      const offer = Number(this.$route.query.oid);
      const gclid = this.$route.query.gclid;

      if (offer) {
        switch (offer) {
          case 10:
            amount = 19.5;
            break;

          case 20:
            amount = 39.5;
            break;

          default:
            amount = 19.5;
        }
      } else {
        amount = this.credits * 0.02;
      }

      loadScript({
        "client-id": PAYPAL_CLIENT_ID,
        currency: this.currency,
      })
        .then((paypal) => {
          this.isPaypalLoading = false;
          paypal
            .Buttons({
              locale: "de_DE",
              style: {
                layout: "horizontal",
                color: "silver",
                shape: "pill",
                tagline: "false",
              },
              createOrder: (data, actions) => {
                return actions.order.create({
                  intent: "CAPTURE",
                  purchase_units: [
                    {
                      amount: {
                        value: amount,
                      },
                    },
                  ],
                  application_context: {
                    shipping_preference: "NO_SHIPPING",
                  },
                });
              },
              onApprove: async (data, actions) => {
                return this.axios
                  .get(
                    "/checkout/paypal?orderid=" +
                      data.orderID +
                      "&code=" +
                      (this.hasCode ? this.couponCode : "") +
                      "&credits=" +
                      this.credits +
                      (offer ? "&offer=" + offer : "") +
                      (gclid ? "&gclid=" + gclid : "")
                  )
                  .then((res) => {
                    if (res.data.code) {
                      let redirectUrl;
                      const offer = this.$route.query.oid;
                      if (offer) {
                        redirectUrl =
                          "/testen/erfolg?code=" +
                          res.data.code +
                          "&paypal=true";

                        const gclid = this.$route.query.gclid;
                        if (gclid)
                          redirectUrl = redirectUrl + "&gclid=" + gclid;
                      } else {
                        redirectUrl =
                          "/zugangscode/erfolg?code=" +
                          res.data.code +
                          "&new=" +
                          (this.hasCode ? 0 : 1) +
                          "&paypal=true";
                      }

                      this.$router.push(redirectUrl);
                    } else {
                      this.error = true;
                    }
                  })
                  .catch((err) => {
                    this.error = true;
                  });
              },
            })
            .render("#paypal-button");
        })
        .catch((err) => {
          this.error = true;
        });
    },
    methods: {
      onCopy() {
        this.$bvModal.msgBoxOk(
          "Ihr Trooia-Zugangscode wurde in die Zwischenablage kopiert. Fügen Sie ihn in die Paypal-Zahlungsmitteilung ein."
        );
      },
      onCopyError() {
        this.$bvModal.msgBoxOk(
          "Ihr Trooia-Zugangscode konnte nicht in die Zwischenablage kopiert werden."
        );
      },
    },
  };
</script>

<style scoped>
  .red {
    color: #940912;
  }

  .btn {
    background-color: #940912;
    border-color: #940912;
  }
  .btn:hover {
    background-color: #aa0510;
    border-color: #aa0510;
  }
</style>
