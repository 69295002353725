<template>
  <b-container class="my-5 px-2 pb-5">
    <b-row>
      <b-col
        cols="12"
        sm="12"
        md="8"
        offset-md="2"
        lg="6"
        offset-lg="3"
        class="px-0 text-center"
      >
        <div v-if="hasError">
          <p class="mt-5 mb-3">
            <font-awesome-icon
              icon="exclamation-triangle"
              size="2x"
              class="text-warning"
            />
          </p>
          <p>
            Ein Fehler ist aufgetreten, bitte erneut versuchen:
          </p>

          <h5 class="mt-4 mb-3">
            <font-awesome-icon icon="chevron-down" class="red" />
          </h5>
          <p class="mt-4">
            <b-button to="/testcode" class="mx-1 px-4 btn-dark rounded-pill ">
              <font-awesome-icon icon="sync-alt" class="mr-2" />Wiederholen
            </b-button>
          </p>
        </div>

        <div v-else-if="isLoading && !hasResponded">
          <p class="mt-5">
            Zugangscode wird versendet
          </p>
          <h1>
            <font-awesome-icon icon="spinner" class="fa-spin" />
          </h1>
        </div>

        <div v-else-if="hasResponded && !hashExists">
          <p class="mt-5 mb-3">
            <font-awesome-icon icon="check" size="2x" class="text-success" />
          </p>
          <p class="mb-0 font-weight-bold">
            Zugangscode erfolgreich versendet
          </p>

          <p class="mt-4">
            Folgen Sie bitte den Anweisungen in der Email, um Ihren Zugang zu
            aktivieren. Falls Sie keine Email erhalten, prüfen Sie bitte Ihren
            Spam-Ordner.
          </p>
          <p class="">
            Sollten Sie Hilfe benötigen, schreiben Sie an:
          </p>
          <h5 class="mt-4 mb-3">
            <font-awesome-icon icon="chevron-down" class="red" />
          </h5>
          <p>
            <b-button
              href="mailto:info@trooia.app?subject=Testcode"
              class="mt-2 px-4 btn-red rounded-pill shadow-none"
            >
              <font-awesome-icon icon="envelope" class="mr-2" />info@trooia.app
            </b-button>
          </p>
        </div>

        <div v-else-if="hasResponded && hashExists">
          <p class="mt-5 mb-3">
            <font-awesome-icon
              icon="exclamation-triangle"
              size="2x"
              class="text-warning"
            />
          </p>
          <p class="mb-0 font-weight-bold">
            Für diese Email-Adresse wurde schon ein Zugangscode angefordert
          </p>

          <p class="mt-4">
            Um Missbrauch vorzubeugen, wird pro Email-Adresse nur ein
            Zugangscode versendet. Wenn Ihnen Trooia gefällt, unterstützen Sie
            das Projekt mit einem kleinen Beitrag:
          </p>
          <h5 class="mt-4 mb-3">
            <font-awesome-icon icon="chevron-down" class="red" />
          </h5>
          <p>
            <b-button
              to="/zugangscode/"
              class="mt-2 px-4 btn-red rounded-pill shadow-none"
            >
              <font-awesome-icon
                icon="shopping-cart"
                class="mr-2"
              />Suchanfragen erwerben
            </b-button>
          </p>
        </div>

        <div v-else>
          <p class="mb-2">
            <b-img :src="require('@/assets/img/horse.png')" id="horse"></b-img>
          </p>
          <h2>Trooia gratis testen</h2>

          <p class="mt-4 hyphened">
            Für Google ist Ihre Privatsphäre dank Ihres Suchverlaufs aus Glas.
            Mit Trooia bleiben Sie beim Suchen im Internet anonym und entspannt.
          </p>

          <p class="mt-4 mb-2 font-weight-bold">
            <font-awesome-icon :icon="['far', 'newspaper']" class="mr-2" />In
            der Presse
          </p>
          <p class="mb-4">
            <b-link
              href="https://cronimund.ch/var/20220718_vybech.pdf"
              target="_blank"
              class="red"
              >Vybe Testbericht<font-awesome-icon
                icon="external-link-alt"
                size="xs"
                class="mr-2 ml-1"
                style="margin-bottom: 1px"
            /></b-link>
            <b-link
              href="https://cronimund.ch/var/20210206_trooia_nzz.pdf"
              target="_blank"
              class="ml-2 red"
              >Neue Zürcher Zeitung<font-awesome-icon
                icon="external-link-alt"
                size="xs"
                class="mr-2 ml-1"
                style="margin-bottom: 1px"
            /></b-link>
            <b-link
              href="https://cronimund.ch/var/20210324_trooia_sueddeutsche.pdf"
              target="_blank"
              class="ml-2 red"
              >Süddeutsche Zeitung<font-awesome-icon
                icon="external-link-alt"
                size="xs"
                class="mr-2 ml-1"
                style="margin-bottom: 1px"
            /></b-link>
          </p>

          <h5 class="mt-4">
            <font-awesome-icon icon="chevron-down" class="red" />
          </h5>

          <p class="mt-4">
            <b-img src="/img/horse-testcode.jpg" rounded fluid-grow></b-img>
          </p>

          <p class="mt-4 mb-2">
            <font-awesome-icon icon="star" size="1x" class="text-warning" />
          </p>
          <p>
            Ein Zugangscode für Trooia mit
            <u>{{ credits }} Gratis-Suchanfragen</u>
            wird an Ihre Email-Adresse gesendet
          </p>

          <p class="mt-4 mb-2">
            <font-awesome-icon icon="shield-alt" class="text-dark" />
          </p>
          <p>
            Ihre Email-Adresse wird nicht gespeichert. Um Missbrauch
            vorzubeugen, wird jedoch eine verschlüsselte Signatur der Adresse
            gespeichert. Ihre Adresse kann daraus nicht mehr rekonstruiert
            werden.
          </p>

          <h5 class="mt-4 mb-3">
            <font-awesome-icon icon="chevron-down" class="red" />
          </h5>

          <b-row class="mt-4">
            <b-col class="pr-sm-1">
              <b-form-input
                ref="email_form"
                v-model="email"
                v-validate="'required|email'"
                name="email"
                v-on:keyup.enter="enterKeyUp"
                placeholder="Ihre Email-Adresse"
                autofocus
                autocomplete="off"
                size="lg"
                class="rounded-pill px-4 text-body"
              >
              </b-form-input>
            </b-col>
            <b-col sm="auto" xs="12" class="pl-sm-1">
              <b-button
                @click="sendCode"
                ref="email_button"
                type="submit"
                size="lg"
                class="mt-2 mt-sm-0 px-4 btn-block btn-red rounded-pill shadow-none"
              >
                <font-awesome-icon icon="envelope" class="mr-2" />Senden
              </b-button>
            </b-col>
          </b-row>
          <p v-if="!isValid" class="mt-2 mb-5 text-center red">
            <font-awesome-icon icon="exclamation-triangle" class="mr-2" />Bitte
            gültige Email-Adresse eingeben
          </p>
          <p v-else class="mb-5"></p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  const API_TESTCODE_ENDPOINT = "/testcode";

  export default {
    data() {
      return {
        src: "",
        credits: 15,
        email: "",
        isValid: true,
        isLoading: false,
        hasResponded: false,
        hashExists: false,
        hasError: false,
      };
    },
    mounted() {
      if (this.$route.params.src) {
        switch (this.$route.params.src) {
          case "promo":
            this.credits = 50;
            this.src = "promo";
            break;

          default:
            this.credits = 25;
            this.src = "";
        }
      } else {
        this.credits = 25;
        this.src = "";
      }

      const code = this.$route.query.code;
      if (code) {
        if (code.match(/^[a-f0-9]{32}$/gm)) {
          this.$router.push("/");
        }
      } else {
        try {
          if (localStorage.code) {
            this.$router.push("/");
          }
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }

      const cookies = this.$cookies.keys();
      cookies.forEach((cookie) => this.$cookies.remove(cookie));
    },
    methods: {
      sendCode() {
        if (this.email.length == 0 || this.errors.first("email")) {
          this.isValid = false;
        } else {
          this.isValid = true;
          this.isLoading = true;
          this.hasResponded = false;
          this.hasError = false;

          this.axios
            .get(
              API_TESTCODE_ENDPOINT +
                "?email=" +
                this.email +
                "&src=" +
                this.src,
              {
                timeout: 5000,
              }
            )
            .then((response) => {
              const res = response.data;
              this.isLoading = false;

              this.hasResponded = true;
              this.hashExists = res.hash_exists ? true : false;
            })
            .catch(() => {
              this.hasResponded = true;
              this.isLoading = false;
              this.hasError = true;
            });
        }
      },
    },
    metaInfo: {
      title: "Trooia | Kostenlos testen",
    },
  };
</script>

<style scoped>
  #horse {
    width: 20px;
    height: 20px;
  }

  .red {
    color: #940912;
  }

  .btn-red,
  .btn-red:focus,
  .btn-red:active {
    background-color: #940912 !important;
    border-color: #940912 !important;
  }

  .btn-red:hover {
    background-color: #aa0510 !important;
    border-color: #aa0510 !important;
  }

  .hyphened {
    hyphens: auto;
  }
</style>
