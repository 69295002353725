<template>
  <b-container class="my-5 pb-5">
    <b-row>
      <b-col cols="0" sm="2" md="3"></b-col>

      <b-col>
        <h2 class="mb-4">
          Support
        </h2>

        <p class="hyphened">
          Wenn Sie Hilfe zu Trooia benötigen oder eine Frage haben, schreiben
          Sie gerne eine Email an:
        </p>

        <p class="text-center">
          <b-button
            href="mailto:info@trooia.app"
            class="mx-1 px-4 rounded-pill btn-red"
          >
            <font-awesome-icon icon="envelope" class="mr-2" />info@trooia.app
          </b-button>
        </p>

        <h2 class="mt-5 mb-4">
          Impressum
        </h2>

        <h6 class="font-weight-bold">Adresse</h6>
        <p>
          Cronimund GmbH<br />
          Geeringstrasse 71<br />
          CH-8049 Zürich<br />
          Schweiz
        </p>

        <h6 class="font-weight-bold">Geschäftsführung</h6>
        <p>
          Christoph Cronimund
        </p>

        <h6 class="font-weight-bold">Eingetragener Firmenname</h6>
        <p>
          Cronimund GmbH
        </p>

        <h6 class="font-weight-bold">Unternehmens-Identifikationsnummer</h6>
        <p>
          CHE-343.489.591
        </p>
      </b-col>

      <b-col cols="0" sm="2" md="3"></b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    mounted() {
      const code = this.$route.query.code;
      if (code) {
        if (code.match(/^[a-f0-9]{32}$/gm)) {
          try {
            localStorage.code = code;
          } catch (err) {} // eslint-disable-line
          this.$emit("couponCode", code);
        }
      } else {
        try {
          if (localStorage.code) {
            this.$emit("couponCode", localStorage.code);
          }
        } catch (err) {} // eslint-disable-line
      }

      const cookies = this.$cookies.keys();
      cookies.forEach((cookie) => this.$cookies.remove(cookie));
    },
    metaInfo: {
      meta: [{ name: "robots", content: "noindex" }],
    },
  };
</script>

<style scoped>
  .btn-red,
  .btn-red:focus,
  .btn-red:active {
    background-color: #940912 !important;
    border-color: #940912 !important;
  }

  .hyphened {
    hyphens: auto;
  }
</style>
