<template>
  <b-container class="my-5 px-0 pb-5">
    <b-row>
      <b-col
        v-if="coupon.error"
        cols="12"
        sm="12"
        md="8"
        offset-md="2"
        lg="6"
        offset-lg="3"
        class="text-center"
      >
        <p class="mt-5 mb-3">
          <font-awesome-icon
            icon="exclamation-triangle"
            size="lg"
            class="red"
          />
        </p>
        <p>
          Ein Fehler ist aufgetreten, bitte mit Klick auf den Link an Trooia
          melden:
        </p>
        <p>
          <b-link
            :href="
              'mailto:info@trooia.app?subject=Fehlercode%20' +
                errorCode +
                '%20aufgetreten'
            "
            class="red"
            ><font-awesome-icon
              icon="envelope"
              class="mr-1"
            />info@trooia.app</b-link
          >
        </p>
        <p class="mt-5 text-secondary font-italic">
          Fehlercode: {{ errorCode }}
        </p>
      </b-col>

      <b-col
        v-else-if="loading"
        cols="12"
        sm="12"
        md="8"
        offset-md="2"
        lg="6"
        offset-lg="3"
        class="text-center"
      >
        <p class="mt-5">
          Zahlungsbestätigung abwarten
        </p>
        <h1>
          <font-awesome-icon icon="spinner" class="fa-spin" />
        </h1>
      </b-col>

      <b-col
        v-else-if="paid == 1"
        cols="12"
        sm="12"
        md="8"
        offset-md="2"
        lg="6"
        offset-lg="3"
        class="text-center hyphened"
      >
        <p class="mb-3">
          <font-awesome-icon icon="check" size="2x" class="text-success" />
        </p>

        <p v-if="coupon.isNew">
          <strong
            >Ihr Zugangscode für Trooia wurde in diesem Browser erfolgreich
            aktiviert</strong
          >
        </p>
        <p v-else>
          <strong>Ihr Guthaben wurde erfolgreich aufgefüllt</strong>
        </p>

        <h5 class="mt-5 mb-3">
          <font-awesome-icon icon="chevron-down" class="red" />
        </h5>
        <p v-if="coupon.isNew" class="text-left">
          Sichern Sie bitte unbedingt Ihren Zugangscode,
          <u>bevor Sie diese Seite schliessen</u>. Der Code kann nicht
          wiederhergestellt oder rückerstattet werden:
        </p>
        <p v-else class="text-left">
          Haben Sie Ihren Zugangscode schon gesichert? Der Code kann nicht
          wiederhergestellt oder rückerstattet werden:
        </p>

        <b-card title="Option 1 (empfohlen)" class="mt-5 shadow-sm">
          <b-card-text>
            Kopieren Sie den Zugangscode in eine Notiz oder ein Dokument:
          </b-card-text>
          <b-card-text class="red" style="hyphens: none">
            {{ coupon.code }}
          </b-card-text>

          <b-button
            v-clipboard:copy="coupon.code"
            v-clipboard:success="onCopy"
            v-clipboard:error="onCopyError"
            size="sm"
            variant="dark"
            pill
            class="ml-2 px-3 shadow-none"
          >
            <font-awesome-icon icon="copy" class="mr-2" />Code kopieren
          </b-button>

          <b-card-text class="mt-3">
            Um Trooia auf anderen Geräten zu aktivieren, fügen Sie den Code auf
            der Startseite von Trooia in das Zugangscode-Feld ein.
          </b-card-text>
        </b-card>

        <b-card title="Option 2" class="mt-4 shadow-sm">
          <b-card-text>
            Senden Sie Ihren Zugangscode an sich selbst:
          </b-card-text>

          <b-button
            size="sm"
            pill
            target="_blank"
            class="px-3 shadow-none"
            :href="
              'mailto:?subject=Trooia%20Link%20inkl.%20Zugangscode&body=Herzlichen%20Dank%2C%20dass%20Sie%20Trooia%20nutzen!%0A%0AVerwenden%20Sie%20folgenden%20Link%2C%20um%20den%20Zugang%20zu%20Trooia%20in%20Ihren%20Ger%C3%A4ten%20und%20Browsern%20zu%20aktivieren%3A%0A%0Ahttps%3A%2F%2Ftrooia.app%2F%3Fcode%3D' +
                coupon.code +
                '%0D%0DBewahren%20Sie%20den%20Link%20sorgf%C3%A4ltig%20auf%2C%20Ihr%20Zugangscode%20kann%20nicht%20wiederhergestellt%20oder%20r%C3%BCckerstattet%20werden.%20%0A%0AAm%20besten%20erstellen%20Sie%20in%20Ihren%20Browsern%20Lesezeichen%20mit%20diesem%20Link.%20So%20wird%20der%20Zugang%20zu%20Trooia%20bei%20jedem%20Besuch%20aktiviert%2C%20wenn%20z.B.%20Browserdaten%20gel%C3%B6scht%20wurden.'
            "
          >
            <font-awesome-icon icon="envelope" class="mr-2" />Code senden
          </b-button>

          <b-card-text class="mt-3">
            Ein Klick auf den Link öffnet eine vorgefertigte Nachricht in Ihrem
            Email-Programm. Prüfen Sie Ihren Posteingang, bevor Sie diese Seite
            schliessen.
          </b-card-text>
        </b-card>

        <b-card title="Option 3" class="mt-4 shadow-sm">
          <b-card-text>
            Öffnen Sie Trooia in einem neuen Fenster und erstellen dann in Ihrem
            Browser ein Lesezeichen davon:
          </b-card-text>

          <b-button
            size="sm"
            pill
            target="_blank"
            class="px-3 shadow-none"
            :href="'/?code=' + coupon.code"
          >
            <font-awesome-icon icon="external-link-alt" class="mr-2" />Trooia
            öffnen
          </b-button>

          <b-card-text class="mt-3">
            Dieser Link enthält den Code, welcher den Zugang zu Trooia in Ihren
            Geräten und Browsern aktiviert.
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        v-else
        cols="12"
        sm="12"
        md="8"
        offset-md="2"
        lg="6"
        offset-lg="3"
        class="text-center"
      >
        <p class="mt-5 mb-2">
          <font-awesome-icon
            icon="exclamation-triangle"
            size="lg"
            class="red"
          />
        </p>
        <p>
          <strong>Ihre Zahlung konnte nicht bestätigt werden</strong>
        </p>
        <p>
          Lassen Sie bitte Ihre Zahlung bestätigen, indem Sie eine Email an
          Trooia senden:
        </p>
        <p>
          <b-link
            :href="
              'mailto:info@trooia.app?subject=Bitte%20Zahlung%20bestätigen%20(' +
                coupon.code +
                ')'
            "
            class="red"
            ><font-awesome-icon
              icon="envelope"
              class="mr-1"
            />info@trooia.app</b-link
          >
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  const API_PAYMENTSTATUS_ENDPOINT = "/checkout/status";
  const API_PAYMENTSTATUS_INTERVALS = 8;

  export default {
    data() {
      return {
        loading: true,
        coupon: {
          isNew: false,
          code: null,
          error: false,
        },
        checkInterval: null,
        paid: 0,
        errorCode: "",
      };
    },
    mounted() {
      const isNew = this.$route.query.new;
      if (isNew) {
        if (isNew == 1) {
          this.coupon.isNew = true;
        } else {
          this.coupon.isNew = false;
        }

        const code = this.$route.query.code;
        if (code) {
          if (code.match(/^[a-f0-9]{32}$/gm)) {
            this.coupon.code = code;
            try {
              localStorage.code = code;
            } catch (err) {} // eslint-disable-line
            this.$emit("couponCode", code);

            const isPaypal = this.$route.query.paypal ? true : false;
            if (isPaypal) {
              this.paid = true;
              this.loading = false;
            } else {
              let checks = API_PAYMENTSTATUS_INTERVALS;
              this.checkInterval = setInterval(() => {
                this.getPaymentStatus();

                checks--;
                if (checks == 0) {
                  clearInterval(this.checkInterval);

                  this.loading = false;
                }
              }, 1000);
            }
          } else {
            this.coupon.error = true;
            this.errorCode = "THX_02";
          }
        } else {
          this.coupon.error = true;
          this.errorCode = "THX_03";
        }
      } else {
        this.coupon.error = true;
        this.errorCode = "THX_01";
      }

      const cookies = this.$cookies.keys();
      cookies.forEach((cookie) => this.$cookies.remove(cookie));
    },
    methods: {
      getPaymentStatus() {
        this.axios
          .get(API_PAYMENTSTATUS_ENDPOINT + "?code=" + this.coupon.code, {
            timeout: 2000,
          })
          .then((response) => {
            const res = response.data;

            if (res) {
              this.paid = res.paid;

              if (this.paid == 1) {
                clearInterval(this.checkInterval);

                this.loading = false;
              }
            } else {
              clearInterval(this.checkInterval);

              this.coupon.error = true;
              this.errorCode = "THX_04";

              this.loading = false;
            }
          })
          .catch(() => {
            clearInterval(this.checkInterval);

            this.coupon.error = true;
            this.errorCode = "THX_05";

            this.loading = false;
          });
      },
      onCopy: function(e) {
        this.$bvModal.msgBoxOk(
          "Ihr Trooia-Zugangscode wurde in die Zwischenablage kopiert. Sichern Sie den Code in einem Dokument oder senden Sie ihn in einer Email oder Textnachricht an sich selbst."
        );
      },
      onCopyError: function(e) {
        this.$bvModal.msgBoxOk(
          "Ihr Trooia-Zugangscode konnte nicht in die Zwischenablage kopiert werden. Bitte wählen Sie eine andere Methode!"
        );
      },
    },
  };
</script>

<style scoped>
  .red {
    color: #940912;
  }

  .btn {
    background-color: #940912;
    border-color: #940912;
  }
  .btn:hover {
    background-color: #aa0510;
    border-color: #aa0510;
  }

  .hyphened {
    hyphens: auto;
  }
</style>
