<template>
  <b-container class="my-5 pb-5 hyphened">
    <b-row>
      <b-col cols="0" sm="2" md="3"></b-col>

      <b-col>
        <h2>
          Nutzungsbedingungen
        </h2>
        <p>
          Diese Nutzungsbedingungen regeln das Verhältnis zwischen Trooia
          („<em>Dienst</em>“) und natürlichen und juristischen Personen, welche
          den Dienst nutzen („<em>Kunden</em>“).
        </p>
        <p>
          Die Nutzungsbedingungen gelten mit Abschluss des Bezahlvorgangs durch
          den Kunden als akzeptiert.
        </p>
        <p>
          Trooia behält sich vor, die Nutzungsbedingungen zu ändern. Änderungen
          können aufgrund fehlender Kundendaten nicht kommuniziert werden.
        </p>

        <h4 class="mt-5">
          Leistungen
        </h4>
        <p>
          Trooia ermöglicht Kunden, via Internet-Browser anonymisierte
          Suchanfragen an Google zu stellen und Suchergebnisse ohne Werbung zu
          erhalten.
        </p>
        <p>
          Trooia bietet zu den Geschäftszeiten (Werktags zwischen 9.00 bis 17.00
          Uhr) für Kunden Support per Email an.
        </p>

        <h4 class="mt-5">
          Verrechnung
        </h4>
        <p>
          Kunden erhalten nach erfolgreichem Bezahlvorgang einen Zugangscode,
          welcher sie zur Nutzung des Dienstes innerhalb angegebener
          Gültigkeitsdauer und Umfang (erworbene Anzahl Suchanfragen)
          berechtigt.
        </p>
        <p>
          Für jede Suchanfrage des Kunden wird die verfügbare Anzahl an
          Suchanfragen um eins verringert. Dies gilt auch, wenn für eine
          Suchanfrage mehr Ergebnisse angefordert werden.
        </p>
        <p>
          Zugangscodes werden nicht rückerstattet und können aufgrund fehlender
          Kundendaten nicht wiederhergestellt werden.
        </p>
        <p>
          Kunden sind deshalb alleinig für die sichere Aufbewahrung der
          Zugangscodes verantwortlich.
        </p>

        <h4 class="mt-5">
          Verfügbarkeit
        </h4>
        <p>
          Trooia ist bemüht, die Verfügbarkeit des Dienstes höchstmöglich und
          Ausfälle so selten und kurz wie möglich zu halten.
        </p>
        <p>
          Trooia ist von der Verfügbarkeit Dritter abhängig und gibt keine
          Verfügbarkeitsgarantien.
        </p>

        <h4 class="mt-5">
          Datensicherheit
        </h4>
        <p>
          Trooia speichert im Browser den für die Nutzung des Dienstes
          notwendigen Zugangscode. Es gilt die
          <b-link target="_blank" href="/datenschutz/" class="red"
            >Datenschutzerklärung von Trooia</b-link
          >.
        </p>

        <h4 class="mt-5">
          Haftung
        </h4>
        <p>
          Unter Vorbehalt zwingender gesetzlicher Bestimmungen schliesst Trooia
          jegliche Haftung für Schäden oder Gewährleistung aus.
        </p>

        <h4 class="mt-5">
          Schlussbestimmungen
        </h4>
        <p>
          Ausschliesslicher Gerichtsstand ist Zürich. Es gilt ausschliesslich
          schweizerisches Recht.
        </p>

        <p>
          <em>Zürich, 22. Juni 2021</em>
        </p>
      </b-col>

      <b-col cols="0" sm="2" md="3"></b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    mounted() {
      const code = this.$route.query.code;
      if (code) {
        if (code.match(/^[a-f0-9]{32}$/gm)) {
          try {
            localStorage.code = code;
          } catch (err) {} // eslint-disable-line
          this.$emit("couponCode", code);
        }
      } else {
        try {
          if (localStorage.code) {
            this.$emit("couponCode", localStorage.code);
          }
        } catch (err) {} // eslint-disable-line
      }

      const cookies = this.$cookies.keys();
      cookies.forEach((cookie) => this.$cookies.remove(cookie));
    },
  };
</script>

<style scoped>
  .red {
    color: #940912;
  }

  .hyphened {
    hyphens: auto;
  }
</style>
