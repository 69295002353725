<template>
  <b-container class="my-5 pb-5">
    <b-row>
      <b-col
        cols="12"
        sm="12"
        md="8"
        offset-md="2"
        lg="6"
        offset-lg="3"
        class="px-0 text-center"
      >
        <p class="mb-2">
          <b-img :src="require('@/assets/img/horse.png')" id="horse"></b-img>
        </p>
        <h2>Guthaben auffüllen</h2>
        <p class="mt-3 hyphened">
          Trooia sammelt keine Daten von Benutzern und finanziert sich nicht
          durch Werbung. Trooia bezahlt die Betriebskosten mit Hilfe kleiner
          Benutzergebühren:
        </p>

        <p class="mt-5 font-weight-bold">
          Anzahl Suchanfragen wählen
        </p>
        <b-form-spinbutton
          id="credits"
          v-model="credits"
          min="250"
          max="2500"
          step="250"
          size="lg"
          class="lead"
          style="color: #940912"
        ></b-form-spinbutton>
        <p class="mt-2 small text-secondary">
          1 Jahr gültig
        </p>

        <p class="mt-4 mb-0 font-weight-bold">
          Preis
        </p>
        <p class="lead red">
          {{ credits * 0.02 }} <span style="font-size: 0.7em">EUR / CHF</span>
        </p>

        <p class="mt-4 mb-0 font-weight-bold">
          Zahlungsmethode
        </p>
        <b-button
          :to="'/stripe?credits=' + credits + '&sid=0&code=' + couponCode"
          class="mx-1 mt-2 px-4 rounded-pill btn-red"
        >
          <font-awesome-icon icon="credit-card" class="mr-2" />Kreditkarte
        </b-button>
        <b-button
          :to="'/paypal?credits=' + credits + '&code=' + couponCode"
          class="mx-1 mt-2 px-4 rounded-pill btn-red"
        >
          <font-awesome-icon :icon="['fab', 'cc-paypal']" class="mr-2" />Paypal
        </b-button>
        <p class="mt-2 small text-secondary">
          Mit einem Kauf akzeptieren Sie die
          <b-link
            :to="'/nutzungsbedingungen/?code=' + couponCode"
            target="_blank"
            ><u class="text-secondary">Nutzungsbedingungen</u></b-link
          >
        </p>

        <p class="mt-5 mb-2">
          <font-awesome-icon icon="star" class="text-warning" />
        </p>
        <p>
          Ihr Trooia-Zugang kann auf mehreren Geräten verwendet und mit Familie,
          Freunden oder Mitarbeiter geteilt werden
        </p>

        <p class="mt-4 mb-2">
          <font-awesome-icon icon="shield-alt" class="text-dark" />
        </p>
        <p>
          Trooia speichert keine Benutzer- und Zahlungsinformationen
        </p>

        <p class="mt-4 mb-2 red">
          <font-awesome-icon icon="heart" />
        </p>
        <p>
          Mit Ihrem Kauf unterstützen Sie die Weiterentwicklung und den Betrieb
          von Trooia. Herzlichen Dank!
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  const API_COUPON_ENDPOINT = "/coupon";
  const COUPONS_TIMEOUT = 2000;

  export default {
    data() {
      return {
        couponCode: "",
        credits: 250,
        hasCode: false,
        isSubscribed: false,
        isCanceled: false,
      };
    },
    mounted() {
      const code = this.$route.query.code;
      if (code) {
        if (code.match(/^[a-f0-9]{32}$/gm)) {
          this.couponCode = code;
          this.getCoupon(code);

          try {
            localStorage.code = code;
          } catch (err) {} // eslint-disable-line
          this.$emit("couponCode", code);

          this.hasCode = true;
        } else {
          this.couponCode = "";
          this.hasCode = false;
        }
      } else {
        try {
          if (localStorage.code) {
            this.couponCode = localStorage.code;
            this.getCoupon(localStorage.code);

            this.$emit("couponCode", localStorage.code);
            this.hasCode = true;
          } else {
            this.couponCode = "";
            this.hasCode = false;
          }
        } catch (err) {
          this.couponCode = "";
          this.hasCode = false;
        }
      }

      const cookies = this.$cookies.keys();
      cookies.forEach((cookie) => this.$cookies.remove(cookie));
    },
    methods: {
      getCoupon(code) {
        this.axios
          .get(API_COUPON_ENDPOINT + "?code=" + code, {
            timeout: COUPONS_TIMEOUT,
          })
          .then((response) => {
            const res = response.data;

            if (res) {
              this.isSubscribed = res.subscribed;
              this.isCanceled = res.subscription_canceled;
            }
          })
          .catch(() => {});
      },
    },
    metaInfo: {
      title: "Trooia | Zugangscode erwerben",
    },
  };
</script>

<style scoped>
  #horse {
    width: 20px;
    height: 20px;
  }

  .red {
    color: #940912;
  }

  .btn-red,
  .btn-red:focus,
  .btn-red:active {
    background-color: #940912 !important;
    border-color: #940912 !important;
  }

  .btn-red:hover {
    background-color: #aa0510 !important;
    border-color: #aa0510 !important;
  }

  .hyphened {
    hyphens: auto;
  }
</style>
