<template>
  <b-container class="my-5 pb-5">
    <b-row>
      <b-col
        cols="12"
        sm="12"
        md="8"
        offset-md="2"
        lg="6"
        offset-lg="3"
        v-if="loading"
        class="text-center"
      >
        <p class="mt-5">
          Bezahldienst kontaktieren
        </p>
        <h1>
          <font-awesome-icon icon="spinner" class="fa-spin" />
        </h1>
      </b-col>
      <b-col v-else-if="hasMaintenance" class="text-center">
        <p class="mt-5 mb-3">
          <font-awesome-icon icon="wrench" size="lg" />
        </p>
        <p>
          An Trooia werden Wartungsarbeiten vorgenommen, bitte kommen Sie in ein
          paar Minuten wieder
        </p>
        <p>
          <b-button
            @click="reloadPage"
            size="sm"
            class="mt-3 px-3 rounded-pill btn-dark"
          >
            <font-awesome-icon icon="redo" class="mr-2" />Neu laden
          </b-button>
        </p>
      </b-col>
      <b-col v-else-if="error" class="text-center">
        <p class="mt-5 mb-3">
          <font-awesome-icon
            icon="exclamation-triangle"
            size="lg"
            class="red"
          />
        </p>
        <p>
          Ein Fehler ist aufgetreten, bitte mit Klick auf den Link melden:
        </p>
        <p>
          <b-link
            :href="
              'mailto:info@trooia.app?subject=Fehlercode%20' +
                errorCode +
                '%20aufgetreten%20(' +
                couponCode +
                ')'
            "
            class="red"
          >
            <font-awesome-icon icon="envelope" class="mr-1" />info@trooia.app
          </b-link>
        </p>
        <p class="mt-5 text-secondary font-italic">
          Fehlercode: {{ errorCode }}
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
        couponCode: "",
        loading: true,
        hasMaintenance: false,
        error: false,
        errorCode: "",
        gclid: "",
      };
    },
    mounted() {
      const gclid = this.$route.query.gclid;
      if (gclid) this.gclid = gclid;
      else this.gclid = "";

      const code = this.$route.query.code;
      if (code) {
        if (code.match(/^[a-f0-9]{32}$/gm)) {
          this.couponCode = code;
          try {
            localStorage.code = code;
          } catch (err) {} // eslint-disable-line
          this.$emit("couponCode", code);

          this.getStripeSession();
        } else {
          this.error = true;
          this.errorCode = "CHK_04";
        }
      } else {
        this.couponCode = "";

        this.getStripeSession();
      }
    },
    methods: {
      getStripeSession() {
        if (this.$route.query.credits && this.$route.query.oid) {
          this.getSession(
            "/checkout/session?credits=" +
              this.$route.query.credits +
              "&offer=" +
              this.$route.query.oid +
              (this.gclid ? "&gclid=" + this.gclid : "")
          );
        } else if (this.$route.query.credits) {
          this.getSession(
            "/checkout/session?code=" +
              this.couponCode +
              "&credits=" +
              this.$route.query.credits
          );
        } else if (this.$route.query.sid) {
          this.getSession(
            "/checkout/session?code=" +
              this.couponCode +
              "&sid=" +
              this.$route.query.sid
          );
        } else {
          this.error = true;
          this.errorCode = "CHK_03";
          this.loading = false;
        }
      },
      getSession(url) {
        this.axios
          .post(url)
          .then((response) => {
            this.hasMaintenance = false;

            const res = response.data;
            if (res.maintenance_active) {
              this.hasMaintenance = true;
            } else if (!res.error && res.key && res.session) {
              this.openPaymentForm(res.key, res.session);
            } else {
              this.error = true;
              this.errorCode = "CHK_01";
            }
          })
          .catch(() => {
            this.error = true;
            this.errorCode = "CHK_02";
          })
          .finally(() => {
            this.loading = false;
          });
      },
      async openPaymentForm(key, session) {
        const stripe = Stripe(key); // eslint-disable-line

        await stripe.redirectToCheckout({ sessionId: session });
      },
      reloadPage() {
        this.$router.go();
      },
    },
    metaInfo: {
      script: [
        { src: "https://js.stripe.com/v3/", async: false, defer: false },
      ],
    },
  };
</script>

<style scoped>
  .red {
    color: #940912;
  }
</style>
