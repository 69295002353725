<template>
  <b-container>
    <router-view @couponCode="setCode" />

    <b-navbar
      fixed="bottom"
      type="dark"
      class="py-0 shadow-sm border-top bg-dark"
    >
      <b-navbar-nav class="mx-auto text-center">
        <b-nav-item
          :href="couponCode ? '/?code=' + couponCode : '/'"
          class="mx-3 text-center"
        >
          <font-awesome-icon icon="home" class="mr-2" /><span
            class="d-none d-sm-inline"
            >Startseite</span
          >
        </b-nav-item>

        <b-nav-item
          :href="
            couponCode ? '/datenschutz/?code=' + couponCode : '/datenschutz/'
          "
          class="mx-3 text-center"
        >
          <font-awesome-icon icon="shield-alt" class="mr-2" /><span
            class="d-none d-sm-inline"
            >Datenschutz</span
          >
        </b-nav-item>

        <b-nav-item
          :href="couponCode ? '/support/?code=' + couponCode : '/support/'"
          class="mx-3 text-center"
        >
          <font-awesome-icon icon="question-circle" class="mr-2" /><span
            class="d-none d-sm-inline"
            >Support &amp; Impressum</span
          >
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
        couponCode: "",
      };
    },
    methods: {
      setCode(code) {
        this.couponCode = code;
      },
    },
  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .multiselect,
  .multiselect__input,
  .multiselect__single {
    font-size: 0.9rem;
  }
  .multiselect__single {
    margin-bottom: 0px;
    padding-top: 0px;
    padding-left: 0px;
    cursor: pointer;
  }
  .multiselect__tags {
    min-height: 30px;
    border: none;
    padding-left: 2px;
    padding-right: 0px;
  }
  .multiselect__select {
    display: none;
  }
  .multiselect__content-wrapper {
    border: none;
    box-shadow: 0 0 3px gray;
  }
  .multiselect__option {
    padding-top: 4px;
    padding-bottom: 6px;
    padding-left: 6px;
    min-height: 0px;
  }
  .multiselect__option--selected {
    background: white;
    color: #6c757d;
    font-weight: normal;
  }
  .multiselect__option--highlight {
    background: white;
    color: #6c757d;
    font-weight: normal;
  }
  .multiselect__option--selected.multiselect__option--highlight {
    background: white;
    color: #6c757d;
    font-weight: normal;
  }
</style>

<style>
  @font-face {
    font-family: "Roboto";
    src: url("./assets/font/roboto-light-webfont.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto";
    src: url("./assets/font/roboto-lightitalic-webfont.woff2") format("woff2");
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: "Roboto";
    src: url("./assets/font/roboto-bold-webfont.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
  }

  body {
    font-family: "Roboto";
  }
</style>
