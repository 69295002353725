<template>
  <b-container class="my-5 pb-5">
    <b-row>
      <b-col
        v-if="coupon.error"
        cols="12"
        sm="12"
        md="8"
        offset-md="2"
        lg="6"
        offset-lg="3"
        class="text-center"
      >
        <p class="mt-5 mb-3">
          <font-awesome-icon
            icon="exclamation-triangle"
            size="lg"
            class="red"
          />
        </p>
        <p>
          Ein Fehler ist aufgetreten, bitte mit Klick auf den Link an Trooia
          melden:
        </p>
        <p>
          <b-link
            :href="
              'mailto:info@trooia.app?subject=Fehlercode%20' +
                errorCode +
                '%20aufgetreten'
            "
            class="red"
            ><font-awesome-icon
              icon="envelope"
              class="mr-1"
            />info@trooia.app</b-link
          >
        </p>
      </b-col>

      <b-col
        v-else-if="loading"
        cols="12"
        sm="12"
        md="8"
        offset-md="2"
        lg="6"
        offset-lg="3"
        class="text-center"
      >
        <p class="mt-5">
          Zahlungsabwickler kontaktieren
        </p>
        <h1>
          <font-awesome-icon icon="spinner" class="fa-spin" />
        </h1>
      </b-col>

      <b-col
        v-else
        cols="12"
        sm="12"
        md="8"
        offset-md="2"
        lg="6"
        offset-lg="3"
        class="text-center"
      >
        <p class="mb-2">
          <b-img :src="require('@/assets/img/horse.png')" id="horse"></b-img>
        </p>
        <h2>Trooia-Abonnement</h2>

        <div v-if="coupon.provider == 1">
          <p class="mt-4 hyphened">
            Sie können Ihre Kreditkarte und Abrechnungsinformationen ändern oder
            das Abonnement künden:
          </p>
          <b-button
            :href="stripePortalSession"
            class="mx-1 mt-3 px-4 rounded-pill shadow-none btn"
          >
            <font-awesome-icon icon="wrench" class="mr-2" />Abonnement verwalten
          </b-button>
        </div>
        <div v-else-if="coupon.provider == 2">
          <p class="mt-4 hyphened">
            Sie können das Abonnement in Ihrem Paypal-Konto künden:
          </p>
          <b-button
            href="https://www.paypal.com/myaccount/autopay/connect/"
            class="mx-1 mt-3 px-4 rounded-pill shadow-none btn"
          >
            <font-awesome-icon icon="wrench" class="mr-2" />Abonnement verwalten
          </b-button>
        </div>

        <p class="mt-5 mb-2">
          <font-awesome-icon icon="redo" />
        </p>
        <p>
          Ihr Suchanfragen-Kontingent für Trooia wird automatisch aufgefüllt
        </p>

        <p class="mt-4 mb-2">
          <font-awesome-icon icon="star" class="text-warning" />
        </p>
        <p>
          Ihr Trooia-Zugang kann auf mehreren Geräten verwendet und
          <u>mit anderen Nutzern</u> (z.B. Mitarbeiter oder Familienmitglieder)
          geteilt werden
        </p>

        <p class="mt-4 mb-2">
          <font-awesome-icon icon="shield-alt" class="text-dark" />
        </p>
        <p>
          Trooia speichert keine Benutzer- und Zahlungsinformationen. Ihr
          Abonnement wird durch den Zahlungsabwickler verwaltet.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  const API_COUPON_ENDPOINT = "/coupon";
  const COUPONS_TIMEOUT = 2000;
  const API_BILLINGPORTAL_ENDPOINT = "/abonnement/portal";

  export default {
    data() {
      return {
        loading: true,
        coupon: {
          code: null,
          error: false,
          provider: 0,
        },
        errorCode: "",
        stripePortalSession: null,
      };
    },
    mounted() {
      const code = this.$route.query.code;
      if (code) {
        if (code.match(/^[a-f0-9]{32}$/gm)) {
          this.coupon.code = code;
          try {
            localStorage.code = code;
          } catch (err) {} // eslint-disable-line
          this.$emit("couponCode", code);

          this.getCoupon(code);
        } else {
          this.$router.push("/");
        }
      } else {
        try {
          if (localStorage.code) {
            this.coupon.code = localStorage.code;
            this.$emit("couponCode", localStorage.code);

            this.getCoupon(localStorage.code);
          } else {
            this.$router.push("/");
          }
        } catch (err) {
          this.$router.push("/");
        }
      }

      const cookies = this.$cookies.keys();
      cookies.forEach((cookie) => this.$cookies.remove(cookie));
    },
    methods: {
      getCoupon(code) {
        this.axios
          .get(API_COUPON_ENDPOINT + "?code=" + code, {
            timeout: COUPONS_TIMEOUT,
          })
          .then((response) => {
            const res = response.data;

            if (res) {
              if (res.subscribed && !res.subscription_canceled) {
                this.coupon.provider = res.provider;

                if (res.provider == 1) {
                  this.getStripeBillingPortal();
                } else {
                  this.loading = false;
                }
              } else {
                this.$router.push("/?code=" + code);
              }
            } else {
              this.coupon.error = true;
              this.errorCode = "SPO_01";
            }
          })
          .catch(() => {
            this.coupon.error = true;
            this.errorCode = "SPO_02";
          });
      },
      getStripeBillingPortal() {
        this.axios
          .get(API_BILLINGPORTAL_ENDPOINT + "?code=" + this.coupon.code, {
            timeout: 2000,
          })
          .then((response) => {
            const res = response.data;

            if (res) {
              if (res.stripe_portal_session) {
                this.stripePortalSession = res.stripe_portal_session;
              } else {
                this.coupon.error = true;
                this.errorCode = "SPO_03";
              }
            } else {
              this.coupon.error = true;
              this.errorCode = "SPO_04";
            }
          })
          .catch(() => {
            this.coupon.error = true;
            this.errorCode = "SPO_05";
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
</script>

<style scoped>
  #horse {
    width: 20px;
    height: 20px;
  }

  .red {
    color: #940912;
  }

  .btn {
    background-color: #940912;
    border-color: #940912;
  }
  .btn:hover {
    background-color: #aa0510;
    border-color: #aa0510;
  }

  .hyphened {
    hyphens: auto;
  }
</style>
