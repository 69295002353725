<template>
  <b-container class="my-5 pb-5 hyphened">
    <b-row>
      <b-col cols="0" sm="1" lg="2"></b-col>

      <b-col>
        <h2>
          Datenschutz
        </h2>
        <p>
          Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere
          der EU-Datenschutzgrundverordnung (DSGVO), ist:
        </p>

        <p>
          <span class="font-weight-bold">Cronimund GmbH</span><br />
          Geeringstrasse 71<br />
          CH-8049 Zürich<br />
          Schweiz
        </p>
        <b-container class="mx-0">
          <b-row>
            <b-col cols="1" class="px-0"
              ><font-awesome-icon icon="envelope" class="mr-1 red"
            /></b-col>
            <b-col class="pr-0"
              ><a class="red text-nowrap" href="mailto:info@trooia.app"
                >info@trooia.app</a
              ></b-col
            >
          </b-row>
          <b-row>
            <b-col cols="1" class="px-0"
              ><font-awesome-icon icon="globe" class="mr-1 red"
            /></b-col>
            <b-col class="pr-0"
              ><a class="red text-nowrap" href="/">https://trooia.app</a></b-col
            >
          </b-row>
        </b-container>

        <h4 class="mt-5">
          Allgemeiner Hinweis
        </h4>
        <p>
          Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und den
          datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz,
          DSG) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf
          Schutz vor Missbrauch ihrer persönlichen Daten. Der Betreiber
          ("<em>Trooia</em>") dieser Seiten nimmt den Schutz Ihrer Daten sehr
          ernst. Trooia behandlet Ihre Daten vertraulich und entsprechend der
          gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>
        <p>
          Trooia weist darauf hin, dass die Datenübertragung im Internet (z.B.
          bei der Kommunikation per Email) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
        </p>
        <p>
          Durch die Nutzung dieser Website erklären Sie sich mit der Erhebung,
          Verarbeitung und Nutzung von Daten gemäss der nachfolgenden
          Beschreibung einverstanden. Diese Webseite kann ohne Registrierung
          besucht werden, auf den Servern werden keine personenbezogenen Daten
          gespeichert.
        </p>

        <h4 class="mt-5">
          Datenschutzerklärung für Cookies
        </h4>
        <p>
          Diese Webseite speichert im Browser einen für die Nutzung des Dienstes
          erforderlichen Zugangscode. Der Zahlungsabwickler (siehe unten) setzt
          Cookies, welche für die Abwicklung des Zahlungsprozesses notwendig
          sind und von Trooia danach wieder gelöscht werden.
        </p>

        <h4 class="mt-5">
          Datenschutzerklärung für Zahlungsabwickler
        </h4>
        <p>
          Um den auf dieser Webseite angebotenen Dienst nutzen zu können, muss
          gegen Bezahlung ein Zugangscode erworben werden. Dafür benötigen die
          Zahlungsabwickler von Ihnen personenbezogene Daten (z.B. eine
          Email-Adresse, Kreditkarte). Trooia speichert keine durch den
          Zahlungsabwickler erhobenen personenbezogenen Daten.
        </p>
        <p>
          Als Zahlungsabwickler werden die Leistungen von
          <a target="_blank" href="https://stripe.com" class="red">Stripe</a>
          und
          <a target="_blank" href="https://paypal.com" class="red">Paypal</a> in
          Anspruch genommen, es gelten deren Datenschutzerklärungen.
        </p>

        <h4 class="mt-5">
          Datenschutzerklärung für Email
        </h4>
        <p>
          Wenn Sie den auf dieser Website angebotenen Newsletter beziehen
          möchten, benötigt Trooia von Ihnen eine Email-Adresse sowie Ihre
          Zustimmung, dass Sie mit dem Empfang des Newsletters einverstanden
          sind. Weitere Daten werden nicht erhoben. Diese Daten werden
          ausschliesslich für den Versand des Newsletters verwendet und nicht an
          Dritte weitergegeben.
        </p>
        <p>
          Die erteilte Einwilligung zur Speicherung der Email-Adresse sowie
          deren Nutzung zum Versand des Newsletters können Sie im Newsletter
          jederzeit widerrufen.
        </p>
        <p>
          Für den Versand eines Zugangscodes zu Testzwecken benötigt Trooia
          ebenfalls eine Email-Adresse, welche nicht gespeichert oder
          weitergegeben wird.
        </p>
        <p>
          Als Versand-Dienst für Emails werden die Leistungen von
          <a target="_blank" href="https://www.mailerlite.com" class="red"
            >MailerLite</a
          >
          und
          <a target="_blank" href="https://www.mailersend.com" class="red"
            >MailerSend</a
          >
          in Anspruch genommen, es gelten die
          <a
            target="_blank"
            href="https://www.mailerlite.com/legal/privacy-policy"
            class="red"
            >Datenschutzerklärung MailerLite</a
          >
          und
          <a
            target="_blank"
            href="https://www.mailersend.com/legal/privacy-policy"
            class="red"
            >Datenschutzerklärung MailerSend</a
          >.
        </p>

        <h4 class="mt-5">
          Datenschutzerklärung für Server-Log-Files
        </h4>
        <p>
          Ihr Browser übermittelt automatisch Daten an die Server dieser
          Webseite. Dies sind:
        </p>
        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
        </ul>
        <p>
          Diese Daten sind nicht bestimmten Personen zuordenbar und werden nicht
          gespeichert. Eine Zusammenführung dieser Daten mit anderen
          Datenquellen wird nicht vorgenommen.
        </p>

        <h4 class="mt-5">
          Datenschutzerklärung für Google
        </h4>
        <p>
          Diese Webseite verwendet keine Dienste von Google, welche
          personenbezogene Daten liefern.
        </p>

        <h4 class="mt-5">
          Allgemeiner Haftungsausschluss
        </h4>
        <p>
          Alle Angaben meines Internetangebotes wurden sorgfältig geprüft.
          Trooia bemüht sich, das Informationsangebot aktuell, inhaltlich
          richtig und vollständig anzubieten. Trotzdem kann das Auftreten von
          Fehlern nicht völlig ausgeschlossen werden, womit Trooia keine
          Garantie für Vollständigkeit, Richtigkeit und Aktualität von
          Informationen auch journalistisch-redaktioneller Art übernehmen kann.
          Haftungsansprüche aus Schäden materieller oder ideeller Art, die durch
          die Nutzung der angebotenen Informationen verursacht wurden, sind
          ausgeschlossen, sofern kein nachweislich vorsätzliches oder grob
          fahrlässiges Verschulden vorliegt.
        </p>
        <p>
          Der Herausgeber kann nach eigenem Ermessen und ohne Ankündigung Texte
          verändern oder löschen und ist nicht verpflichtet, Inhalte dieser
          Webseite zu aktualisieren. Die Benutzung bzw. der Zugang zu dieser
          Website geschieht auf eigene Gefahr des Besuchers. Der Herausgeber,
          seine Auftraggeber oder Partner sind nicht verantwortlich für Schäden,
          wie direkte, indirekte, zufällige, vorab konkret zu bestimmende oder
          Folgeschäden, die angeblich durch den Besuch dieser Webseite
          entstanden sind und übernehmen hierfür folglich keine Haftung.
        </p>
        <p>
          Der Herausgeber übernimmt ebenfalls keine Verantwortung und Haftung
          für die Inhalte und die Verfügbarkeit von Webseiten Dritter, die über
          externe Links dieser Webseite erreichbar sind. Für den Inhalt der
          verlinkten Seiten sind ausschliesslich deren Betreiber verantwortlich.
          Der Herausgeber distanziert sich damit ausdrücklich von allen Inhalten
          Dritter, die möglicherweise straf- oder haftungsrechtlich relevant
          sind oder gegen die guten Sitten verstossen.
        </p>

        <h4 class="mt-5">
          Änderungen
        </h4>
        <p>
          Trooia kann diese Datenschutzerklärung jederzeit ohne Vorankündigung
          anpassen. Es gilt die jeweils aktuelle, auf dieser Webseite
          publizierte Fassung.
        </p>

        <p>
          <em>Zürich, 31. Januar 2022</em>
        </p>
      </b-col>

      <b-col cols="0" sm="1" lg="2"></b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    mounted() {
      const code = this.$route.query.code;
      if (code) {
        if (code.match(/^[a-f0-9]{32}$/gm)) {
          try {
            localStorage.code = code;
          } catch (err) {} // eslint-disable-line
          this.$emit("couponCode", code);
        }
      } else {
        try {
          if (localStorage.code) {
            this.$emit("couponCode", localStorage.code);
          }
        } catch (err) {} // eslint-disable-line
      }

      const cookies = this.$cookies.keys();
      cookies.forEach((cookie) => this.$cookies.remove(cookie));
    },
    metaInfo: {
      meta: [{ name: "robots", content: "noindex" }],
    },
  };
</script>

<style scoped>
  .red {
    color: #940912;
  }

  .hyphened {
    hyphens: auto;
  }
</style>
